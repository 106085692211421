import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import {BackOptions, BackShareService} from '@app/core/services/back-share.service';
import { DataShareService } from '@app/core/services/data-share.service';
import { StateService } from '@app/core/services/state.service';
import { IPatientInfo, IUserInfo } from '@app/models/interfaces/users.interface';
import {MediaMatcher} from '@angular/cdk/layout';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnInit, AfterViewInit{
  public noAvatar = 'assets/images/users/no-avatar.png';
  @Input() isExpanded: boolean;
  doctorInfo: IUserInfo;
  patientInfo: IPatientInfo;
  patientName: string;
  showLogo: boolean;
  showPatientInfoInHeader: boolean;
  headerInWhite: boolean;
  isVideoCallScreen: boolean;
  mobileQuery: MediaQueryList;
  type: 'chats' | 'selectChats' | undefined;
  backOptions: BackOptions;
  @ViewChild('headerItem') headerItem: ElementRef<HTMLDivElement>;
  @Output() setExpanded: EventEmitter<boolean> = new EventEmitter<boolean>();
  private mobileQueryListener: () => void;

  constructor(
    private _backShareService: BackShareService,
    private _authService: AuthService,
    private _dataShareService: DataShareService,
    private _router: Router,
    private _stateService: StateService,
    private media: MediaMatcher,
    private cd: ChangeDetectorRef
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 767.98px)');
    this.mobileQueryListener = () => cd.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
    this._backShareService.current.subscribe(options => {
      this.backOptions = options;
    });

    this._stateService.showPatientInfoInHeader.subscribe(value => {
      this.showPatientInfoInHeader = value;
    });

    this.doctorInfo = this._authService.getUser();
    this._stateService.patientInfo.subscribe(patient =>
      {
        if (patient) {
          this.patientInfo = patient;
          if (patient) {
            this.showLogo = true;
          }
          this.patientName = `${patient?.firstName} ${patient?.lastName}`;
        }
      });
    this._stateService.headerInWhite.subscribe((value: boolean) => {
      this.headerInWhite = value;
    });

    this._stateService.isVideoCallScreen.subscribe(value => {
      this.isVideoCallScreen = value;
    });
  }

  ngAfterViewInit(): void {
    let count = 0;
    const setHeight = setInterval(() => {
      this._stateService.headerHeight.next(this.headerItem?.nativeElement.offsetHeight || 0);
      count++;
      if (count === 5) {
        clearInterval(setHeight);
      }
    }, 1000);
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.getProfileStorage();
    }, 3000);
  }

  getProfileStorage() {
    this._dataShareService.data.subscribe(response => {
      const profile = response || this._authService.getUser();
      this.doctorInfo.firstName = profile.firstName || this.doctorInfo.firstName;
      this.doctorInfo.lastName = profile.lastName || this.doctorInfo.lastName;
      this.doctorInfo.avatarUrl = profile.avatarUrl || this.doctorInfo.avatarUrl;
      this.doctorInfo.specialty = profile.specialty || this.doctorInfo.specialty;
      this._authService.setUser(this.doctorInfo);
      this.cd.detectChanges();
    });
  }

  onReturnList(): void {
    this.backOptions.triggerBack = false;
    this._backShareService.change(this.backOptions);
    if (this.isVideoCallScreen) {
      this._stateService.headerInWhite.next(true);
    }
    if (this.isVideoCallScreen)
    {
      this._stateService.showPatientProfileInVideoCallScreen.next(false);
      this._stateService.showPatientInfoInHeader.next(true);
      return;
    }
  }
  onLogout(): void {
    this._router.navigate(['/logout']);
  }
}
