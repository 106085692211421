<div class="forgot-password">
  <div class="title txt-center">
    <h4>Set new password</h4>
    <h5>Your new password must be different to previously used passwords</h5>
    <span class="error-show" *ngIf="isHasError">Password is required and invalid.</span>
    <span class="error-show" *ngIf="!isHasError && !isMatchingPassword">Confirm password is not match.</span>
  </div>

  <div class="form">
    <form class="flex-grow-1 d-flex flex-column justify-content-center" [formGroup]="passwordForm" class="mt-2">
      <div class="form-group">
        <label class="">Password</label>
        <mat-form-field class="" appearance="outline">
          <input [type]="showNewPassword ? 'text' : 'password'" matInput #input formControlName="newPassword"
            class="round-coner" [class.error-border]="isHasError && f.newPassword.invalid" />
          <mat-icon matSuffix (click)="toggleNewPasswordVisibility()">
            {{showNewPassword?'visibility':'visibility_off'}}
          </mat-icon>
        </mat-form-field>
      </div>
      <div class="form-group">
        <label class="">Confirm Password</label>
        <mat-form-field class="" appearance="outline">
          <input [type]="showConfirmPassword ? 'text' : 'password'" matInput #input formControlName="confirmPassword"
            class="round-coner" [class.error-border]="isHasError && f.confirmPassword.invalid" />
          <mat-icon matSuffix (click)="toggleConfirmPasswordVisibility()">
            {{showConfirmPassword?'visibility':'visibility_off'}}
          </mat-icon>
        </mat-form-field>
      </div>
      <div class="txt-center">
        <button mat-stroked-button class="btn btn-blue" (click)="submit()">Reset password</button>
      </div>
    </form>
  </div>
</div>
