import { Injectable } from '@angular/core';
import {IChannelLastRead, IChannelMessage, IChannelsResponse, IChatShareService} from '@app/models/interfaces/chat.interface';
import { IUserInfo } from '@app/models/interfaces/users.interface';
import { AuthService } from './auth.service';
import { ChatService } from './chat.service';
import * as _ from 'underscore';
import { DateUltil } from '../utils/date.util';

@Injectable({
  providedIn: 'root'
})
export class ChatShareService {
  currentUser: IUserInfo;
  chatSharedMessage: IChatShareService = {
    channels: [],
    hasData: true
  };
  constructor(public chatService: ChatService,
              public authService: AuthService) {
    this.currentUser = this.authService.getUser();
  }



  unSubscribeChannels() {
    const client = this.chatSharedMessage.client
    if (!client) {
      return;
    }
    const channels = this.chatSharedMessage.channels;
    channels.forEach((channelItem: IChannelMessage) => {
      const ablyChannel = client.channels.get(channelItem.channelId);
      ablyChannel.unsubscribe();
    })
  }

  clearChannel() {
    this.unSubscribeChannels();
    this.chatSharedMessage.channels = [];
    this.chatSharedMessage.hasData = false;
    this.chatSharedMessage.channelSelected = undefined;
    this.chatSharedMessage.client = undefined;
  }

  saveChannelLocalStorage(channelId: string) {
    if (!this.chatSharedMessage.channels || !this.currentUser) {
      return;
    }
    this.chatService.removeChannelLocalStorage(channelId, this.currentUser.id.toString());
    const channel = this.chatSharedMessage.channels.filter((item) => item.channelId === channelId)[0];
    if (!channel || !channel.lastMessage) {
      return;
    }

    const timestampVal: any = Number(channel.lastMessage?.timestampStr)
        ? Number(channel.lastMessage?.timestampStr)
        : channel.lastMessage?.timestampStr;

    const timestamp = DateUltil.dateToUnix(new Date(timestampVal));

    const channelStorage: IChannelLastRead = {
      channelId,
      timestamp: timestamp,
      userId: this.currentUser.id.toString()
    };

    this.chatService.setChannelLocalStorage(channelStorage);
  }


  calculateUnreadChannel(channelId: string): void {
    if (!this.chatSharedMessage.channels || !this.currentUser) {
      return;
    }
    const channel = this.chatSharedMessage.channels.filter((item) => item.channelId === channelId)[0];
    if (!channel || !channel.messages) {
      return;
    }

    if (channel.channelId === this.chatSharedMessage.channelSelected?.channelId) {
      channel.totalUnreadMessage = 0;
      return;
    }
    const channelStorage = this.chatService.getChannelLocalStorage(channel.channelId, this.currentUser.id.toString());
    if (!channelStorage) {
      return;
    }
    channel.totalUnreadMessage = _.filter(channel.messages, (item) => {
      if (!channelStorage?.timestamp) {
        return true;
      }

      const timestamp: any = Number(item?.timestampStr)
        ? Number(item.timestampStr)
        : item.timestampStr;

      const itemUnix = DateUltil.dateToUnix(new Date(timestamp));

      return itemUnix > channelStorage.timestamp;
    }).length;
  }


  public reloadChannels() {
    this.chatService.getChannelsApi().subscribe((response: IChannelsResponse) => {
      if (response && response.token) {
        const channel = this.chatSharedMessage.client?.channels.get(response.channelSecret.channelId);
        const message = {
          name: 'LinkedChannel',
          data: {
            channels: response.channels
          }
        };
        channel?.publish(message);
      }
    });
  }
}
