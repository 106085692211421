<div class="forgot-password txt-center">
  <div class="title">
    <h4>Check your email</h4>
    <h5>We sent a password reset link to</h5>
    <h5><b>{{email}}</b></h5>
  </div>
  <div class="resend-request">
    <h5>Didn't receive the email? <a class="pointer" [ngClass]="{'disabled': disabled}" (click)="resend()">Click to
        resend</a></h5>
    <p>{{display }} Seconds</p>
  </div>
</div>