import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface BackOptions {
  triggerBack: boolean;
  title?: string;
}

export enum BackTitle {
  APPOINTMENT = 'Appointment List',
  VIDEO = 'Video Call',
  CHATS = 'Chat List',
  SELECT_CHATS = 'Select Chat',
  PATIENT = 'Patient List',
}

@Injectable({
  providedIn: 'root'
})
export class BackShareService {

  private _dataSource: BehaviorSubject<BackOptions>;
  current: Observable<BackOptions>;

  constructor() {
    this._dataSource = new BehaviorSubject<BackOptions>({
      triggerBack: false
    });
    this.current = this._dataSource.asObservable();
  }

  change(option: BackOptions) {
    this._dataSource.next(option);
  }
}
