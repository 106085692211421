import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UtilService } from '@core/services/util.service';
import { Types } from 'ably';
import { IChannelsResponse, IMessageHistory } from '@app/models/interfaces/chat.interface';
import { DataResponse, IScrollResponse } from '@app/models/interfaces/common.interface';

const routes = {
  getChannelsUrl: '/chat/channel-chat',
  getHistoryChannelUrl: '/chat/get-chat-histories',
  remindPatientUrl: (userId: string) => `/notification/remind-patient?userId=${userId}`,
};


@Injectable({
  providedIn: 'root'
})
export class ChatApiService {

  constructor(
    private apiService: ApiService,
    private utilService: UtilService
  ) { }

  getChannelsApi(): Observable<IChannelsResponse> {
    return this.apiService.get(routes.getChannelsUrl);
  }

  getHistoriesChannelApi(channelId: string, timestamp: string): Observable<DataResponse<IScrollResponse<IMessageHistory>>> {
    const body: any = {
      channelId,
      lastTimeStamp: timestamp
    };
    return this.apiService.post(routes.getHistoryChannelUrl, body);
  }

  remindPatientChat(userId: string): Observable<any> {
    return this.apiService.post(routes.remindPatientUrl(userId), {});
  }
}
