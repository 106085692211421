<div class="forgot-password">
  <div class="title txt-center">
    <h4>Forgot password?</h4>
    <h5>No worries, we'll send you reset instructions.</h5>
  </div>
  <span class="error-show" *ngIf="isHasInvalid">Email is required and invalid.</span>
  <div class="form">
    <form class="flex-grow-1 d-flex flex-column justify-content-center" [formGroup]="forgotPasswordForm"
      (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email" class="ff-Agrandir-medium">Email</label>
        <input id="email" type="email" class="form-control" formControlName="email" placeholder="Enter your email">
      </div>
      <button type="submit" mat-stroked-button class="btn btn-blue">Reset password</button>
    </form>
  </div>
</div>