import { Injectable } from '@angular/core';
import { AuthApiService } from './api/auth-api.service';
import {Observable, tap} from 'rxjs';
import { ILoginBody, ILogin } from '@interfaces/auth.interface';
import { Ability } from '@casl/ability';
import { IUserInfo } from '@app/models/interfaces/users.interface';
import jwt_decode from 'jwt-decode';
import {DoctorService} from '@core/services/doctor.service';
import {DataShareService} from '@core/services/data-share.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private authApiService: AuthApiService,
        private doctorService: DoctorService,
        private dataShareService: DataShareService,
        private ability: Ability,
    ) { }

    clearToken(): void {
        localStorage.clear();
    }
    setUser(user: IUserInfo): void {
        let eazyScriptId = this.fromTokenToUser()?.eazyScriptId;
        user.eazyScriptId = eazyScriptId;
        localStorage.setItem('userInfo', JSON.stringify(user));
    }
    getUser(): IUserInfo {
        let userJson: string;
        userJson = localStorage.getItem('userInfo') || '';
        const user = userJson ? JSON.parse(userJson) : undefined;
        return user;
    }
    setToken(token: string): void {
        localStorage.setItem('token', JSON.stringify(token));
    }
    getToken(): string {
        let token: string;
        token = localStorage.getItem('token') || '';
        try {
            return JSON.parse(token);
        } catch(e) {
            return token ? token : '';
        }
        
    }

    isLoggedIn(): boolean {
        let token: any = this.getToken();
        if (!token) {
            return false;
        } else {
            token = jwt_decode(token);
            const expires = new Date(token.exp * 1000);
            const timeout = expires.getTime() - Date.now();
            setTimeout(() => this.logout(), timeout);
            return true;
        }
    }

    fromTokenToUser() {
        const userToken: any = jwt_decode(this.getToken());
        return {
            id: parseInt(userToken.user_id, 0),
            avatarUrl: userToken.avatar,
            email: userToken.email,
            firstName: userToken.user_first_name,
            lastName: userToken.user_last_name,
            gender: parseInt(userToken.gender, 0),
            phone: userToken.phone_number,
            birthday: userToken.birthday,
            address: userToken.business_address,
            eazyScriptId: userToken.eazyscript_account_id
        };
    }

    login(data: ILoginBody): Observable<ILogin> {
        return this.authApiService.loginApi(data).pipe(tap(res => {
            const { token } = res;
            this.setToken(token);
            this.storeDoctor();
            return res;
        }));
    }

    storeDoctor(doctor?: any) {
        this.doctorService.getSpecialties().subscribe(response => {
            const user: IUserInfo = doctor || this.fromTokenToUser();
            user.specialty = response.find((splt: any) => splt.value === Number(user.specialty))?.text || '';
            this.dataShareService.updateDoctorInfo(user);
            this.setUser(user);
        });
    }

    logout(): void {
        this.ability.update([]);
        localStorage.clear();
    }

    requestForgotPassword(data: any): Observable<any> {
        return this.authApiService.requestForgotPassword(data);
    }

    checkRequestPassword(code: string): Observable<any> {
        return this.authApiService.checkRequestPassword(code);
    }

    updateNewPassword(data: any): Observable<any> {
        return this.authApiService.updateNewPassword(data);
    }
}
