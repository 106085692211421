import { Component } from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer,
  ) {
    this._matIconRegistry.addSvgIcon('ic-pdf', this._domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/ic-pdf.svg'));
  }
}
