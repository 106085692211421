import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chatdate'
})
export class ChatDatePipe implements PipeTransform {

  transform(dateStr?: string): any {
    if (!dateStr) return '';

    const dayDiff = this.calculateDiff(dateStr);
    const date = new Date(this.parseVal(dateStr));
    if (dayDiff <= 0) {
      return formatDate(date, 'hh:mm a', 'en_US');
    }
    
    return formatDate(date, 'MMM dd, yyyy hh:mm a', 'en_US');
  }

  calculateDiff(dateStr: any){
    const date = new Date(this.parseVal(dateStr));
    const currentDate = new Date();

    const days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);
    return days;
  }
  parseVal(dateStr: string): any{
    const val = Number(dateStr);
    if (val) return val;
    return dateStr;
  }

}