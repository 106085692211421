<div class="forgot-password">
  <div class="content">
    <div class="logo txt-center">
      <img src="assets/images/logo.png" />
    </div>
    <app-forgot-password-s1 *ngIf="step === 1" (requestResetPassword)="requestResetPassword($event)">
    </app-forgot-password-s1>
    <app-forgot-password-s2 *ngIf="step === 2" [email]="email" (resendForgotPassword)="resendForgotPassword($event)">
    </app-forgot-password-s2>
    <app-forgot-password-s3 *ngIf="step === 3" (resetPassword)="resetPassword($event)">
    </app-forgot-password-s3>
    <app-forgot-password-s4 *ngIf="step === 4"></app-forgot-password-s4>
    <div class="back-login txt-center">
      <a href="/login">
        <mat-icon>keyboard_backspace</mat-icon>
        <span>Back to log in</span>
      </a>
    </div>
  </div>
</div>
