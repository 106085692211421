import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ILoginBody } from '@interfaces/auth.interface';
import { AuthService } from '@core/services/auth.service';
import { Subject } from 'rxjs';
import { DoctorService } from '@app/core/services/doctor.service';
import { DataShareService } from '@app/core/services/data-share.service';

@Component({
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageContainerComponent implements OnInit {

  private destroy$ = new Subject();
  public message: any;
  public userToken: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private doctorService: DoctorService,
    private dataShareService: DataShareService,
    private router: Router,
  ) {
    this.message = {
      message: '',
      type: ''
    };
  }

  ngOnInit(): void {
    // this.authService.logout();
    this.activatedRoute.queryParams.subscribe(params => {
      const state = params.state;
      if (state === 'reset-password-succeed') {
        this.message = {
          message: `Awesome, you've successfully updated your password.`,
          type: 'success'
        };
      }
    });

    const isLoggedIn = this.authService.isLoggedIn();
    if (isLoggedIn) {
      this.doctorService.getDoctorProfile().subscribe(res => {
        this.authService.storeDoctor(res);
      });
    }
  }

  public onLogin(data: ILoginBody): void {
    this.authService.login(data).subscribe(
      () => {
        this.doctorService.updateDoctorTimeZone().subscribe();
        setTimeout(()=> {
          this.router.navigate(['/appointment'])
         }, 1000);
      },
      error => {
        const { message } = error;
        this.message = {
          message,
          type: 'error'
        };
      },
    );
  }
}
