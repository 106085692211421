const startOfDate = (): Date => {
  const date = new Date();
  const result = date.setHours(0, 0, 0, 0);
  return new Date(result);
};

const setTimeToDate = (date: Date, time: Date) => {
  const newDate  = new Date(date.getTime());
  const result = newDate.setHours(time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds());
  return new Date(result);
};

const addMinutes = (date: Date, n: number) => {
  const newDate = new Date(date.getTime());
  const result = newDate.setTime(newDate.getTime() + n * 60000);
  return new Date(result);
};

const endOfDate = (): Date => {
  const date = new Date();
  const result = date.setHours(23, 59, 59, 999);
  return new Date(result);
};

const startOfMonth = (): Date => {
  const date = new Date();
  const result = new Date(date.getFullYear(), date.getMonth(), 1);
  return result;
};

const endOfMonth = (): Date => {
  const date = new Date();
  const result = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return result;
};

const addMinutesToDate = (date: Date, n: number) => {
  date.setTime(date.getTime() + n * 60000);
  //return d.toISOString().split('.')[0].replace('T',' ');
  return date;
};

const addDaysToDate = (date: Date, n: number) => {
  date.setDate(date.getDate() + n);
  //return d.toISOString().split('T')[0];
  return date;
};
const dateToUnix = (date: Date): number => {
  return Math.floor(date.getTime() / 1000);
};

const unixToDate = (unix: number): Date => {
  return new Date(unix  * 1000);
};

const differenceInSeconds = (datetime1 : Date, datetime2 : Date, round  = false) => {
  const interval = (datetime2.getTime() - datetime1.getTime()) / 1000;
  return round ? Math.ceil(interval) : interval;
}

const differenceInMinutes = (datetime1 : Date, datetime2 : Date, round  = false) => {
  let interval = (datetime2.getTime() - datetime1.getTime()) / 1000;
  interval /= 60;
  return round ? Math.ceil(interval) : interval;
}

const differenceInHours = (datetime1 : Date, datetime2 : Date, round  = false) => {
  let interval = (datetime2.getTime() - datetime1.getTime()) / 1000;
  interval /= 60*60;
  return round ? Math.ceil(interval) : interval;
}

const differenceInDays = (datetime1 : Date, datetime2 : Date, round  = false) => {
  let interval = (datetime2.getTime() - datetime1.getTime()) / 1000;
  interval /= 60*60*24;
  return round ? Math.ceil(interval) : interval;
}

const FORMAT_SHORT_DATE = 'yyyy-MM-dd';
const FORMAT_SCHEDULER_DATE = 'yyyyMMdd';
const LOCALE = 'en-US';
export const DateUltil = {
  startOfDate,
  endOfDate,
  startOfMonth,
  endOfMonth,
  addMinutes,
  addMinutesToDate,
  addDaysToDate,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  dateToUnix,
  unixToDate,
  setTimeToDate,
  FORMAT_SHORT_DATE,
  LOCALE,
  FORMAT_SCHEDULER_DATE
};
