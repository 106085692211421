import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    NavigationEnd
} from '@angular/router';
import {DoctorService} from '@core/services/doctor.service';
import {Subject} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
    private loadUser = new Subject();
    constructor(private router: Router, private authService: AuthService, private doctorService: DoctorService) {
        this.loadUser.subscribe(() => {
            this.doctorService.getDoctorProfile().subscribe(res => {
                this.authService.storeDoctor(res);
            });
        });
    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        const isLoggedIn = this.authService.isLoggedIn();
        if (!isLoggedIn) {
            this.router.navigate(['/login']);
            return false;
        }else {
            this.router.events
                .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
                .subscribe(event => {
                    if (event.id === 1) {
                        // Your code here for when the page is refreshd
                        this.loadUser.next(true);
                    }
                });
        }
        return true;
    }
}
